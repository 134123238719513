/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import SocialImage from "./../assets/images/colormatics-social.jpg";

function SEO({
  description,
  lang,
  meta,
  title,
  image,
  author,
  type,
  uid,
  pageType,
}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
            title
            description
            author
          }
        }
      }
    `
  );

  const getCanonical = (uid, type, base) => {
    if (type === "case_study") {
      return base + "/case-study/" + uid + "/";
    }

    if (type === "article" || type === "service") {
      return base + "/" + type + "/" + uid + "/";
    }

    if (type === "service_page") {
      return base + "/service/" + uid + "/";
    }

    if (type === "industry_page") {
      return base + "/industry/" + uid + "/";
    }

    if (uid === "homepage") {
      return base + "/";
    }

    return base + "/" + uid + "/";
  };

  const metaDescription = description || site.siteMetadata.description;
  const metaAuthor = author || site.siteMetadata.author;
  const metaType = type || "website";
  const metaImage = image || `${site.siteMetadata.siteUrl}${SocialImage}`;
  const cononicalURL =
    uid && pageType
      ? getCanonical(uid, pageType, site.siteMetadata.siteUrl)
      : site.siteMetadata.siteUrl;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      link={[
        {
          rel: "canonical",
          href: cononicalURL,
        },
      ]}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: metaType,
        },
        {
          property: `og:image`,
          content: metaImage,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:creator`,
          content: metaAuthor,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          property: `twitter:image`,
          content: metaImage,
        },
      ].concat(meta)}
    />
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
  author: `@colormatics`,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  author: PropTypes.string,
};

export default SEO;
